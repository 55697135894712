
import User from "@/interfaces/User";
import { defineComponent, onMounted, PropType, ref } from "vue";

export default defineComponent({
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  setup(props) {
    const temRedacao = ref<boolean>();

    onMounted(() => {
      temRedacao.value =
        props.user.disciplinas.filter((d) => d.nome.includes("reda") || d.nome.includes("por")).length > 0;
    });

    return { temRedacao };
  },
});
